import { WppTypography, WppActionButton, WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import React, { ReactNode } from 'react'

import styles from 'components/emptyState/EmptyState.module.scss'
import { NoNewsSources } from 'components/svg/noNewsSources/noNewsSources'

export const EmptyState = ({
  className,
  icon,
  title,
  button,
  tooltipText,
}: {
  className?: string
  icon?: ReactNode
  title?: string
  button?: { onClick: React.MouseEventHandler<HTMLElement>; text: string; disabled?: boolean }
  tooltipText?: string
}) => {
  const Tooltip = ({ children, text }: { children: ReactNode; text?: string }): JSX.Element =>
    text ? <WppTooltip text={text}>{children}</WppTooltip> : <>{children}</>

  return (
    <div className={clsx(styles.emptyState, className)} data-testid="empty-state">
      {icon ? icon : <NoNewsSources data-testid="empty-state-icon" />}
      {title && (
        <WppTypography type="m-strong" data-testid="empty-state-title">
          {title}
        </WppTypography>
      )}
      {button && (
        <Tooltip text={tooltipText}>
          <WppActionButton onClick={button.onClick} data-testid="empty-state-button" disabled={button.disabled}>
            {button.text}
          </WppActionButton>
        </Tooltip>
      )}
    </div>
  )
}
