import {
  WppGrid,
  WppTypography,
  WppSpinner,
  WppButton,
  WppIconService,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppInput,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType, MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { ReactNode, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useNewsApi } from 'api/news/queries/useNewsApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { Select } from 'components/common/select/Select'
import { EditNewsItem } from 'components/editNewsItem/EditNewsItem'
import { EmptyState } from 'components/emptyState/EmptyState'
import { NewsCard } from 'components/newsCard/NewsCard'
import { NoHiddenNewsYet } from 'components/svg/noHiddenNewsYet/noHiddenNewsYet'
import { NoNewsAvailable } from 'components/svg/noNewsAvailable/noNewsAvailable'
import { NoStarredNewsYet } from 'components/svg/noStarredNewsYet/noStarredNewsYet'
import { Delay } from 'constants/delay'
import { Permission } from 'constants/permission'
import { useCountries } from 'hooks/useCountries'
import { useCredentials } from 'hooks/useCredentials'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useHasPermission } from 'hooks/useHasPermissions'
import styles from 'pages/newsList/NewsList.module.scss'
import { News } from 'types/news/news'
import { EVENTS } from 'utils/events'

enum TABS {
  ALL = 'all',
  PRIMARY = 'primary',
  HIDDEN = 'hidden',
}

interface State {
  isEditNewsItemModalOpen: boolean
  newsItem: MayBeNull<News>
  selectedTab: TABS
  search: string
  countryAlpha2Codes: string[]
  tenantCredentialsIds: string[]
  primary: MayBeNull<boolean>
  hidden: boolean
}

export const NewsList = () => {
  const { osContext, osApi } = useOs()
  const { tenant } = osContext
  const { hasPermission } = useHasPermission()
  const hasAccessToNewsSettings = hasPermission(Permission.NewsSettingsManage)

  const defaultFilterValues = {
    search: '',
    countryAlpha2Codes: [],
    tenantCredentialsIds: [],
    primary: null,
    hidden: false,
  }

  const [
    {
      isEditNewsItemModalOpen,
      newsItem,
      selectedTab,
      search,
      hidden,
      primary,
      tenantCredentialsIds,
      countryAlpha2Codes,
    },
    setState,
  ] = useSetState<State>({
    isEditNewsItemModalOpen: false,
    newsItem: null,
    selectedTab: TABS.ALL,
    ...defaultFilterValues,
  })

  const { isLoading, data: news } = useNewsApi({
    params: {
      tenantId: tenant.azMeta.organizationsId,
      search,
      hidden,
      primary,
      tenantCredentialsIds,
      countryAlpha2Codes,
    },
  })
  const { credentialOptions, isLoading: isCredentialsLoading } = useCredentials()
  const { countriesOptions, isLoading: isCountriesLoading, normalizedCountries } = useCountries()

  const setSearchDebounced = useDebounceFn(
    (search?: string) => setState({ search: search?.trim() || '' }),
    Delay.Search,
  )

  const NewsCardWrapper = ({
    openInsideOs,
    children,
    id,
    postUrl,
  }: {
    openInsideOs: boolean
    children: ReactNode
    id: string
    postUrl: string
  }): JSX.Element =>
    openInsideOs ? (
      <Link to={`/${id}`}>{children}</Link>
    ) : (
      <a href={postUrl} target="_blank" rel="noreferrer">
        {children}
      </a>
    )

  const content = useCallback(
    (isLoading: boolean, news: News[]) => {
      if (isLoading) {
        return (
          <Flex align="center" justify="center" style={{ height: '100%' }}>
            <WppSpinner size="m" />
          </Flex>
        )
      } else if (!news.length) {
        switch (selectedTab) {
          case TABS.ALL:
            return (
              <EmptyState
                title="No news available"
                className={styles.emptyState}
                icon={<NoNewsAvailable data-testid="all-empty-state-icon" />}
              />
            )
          case TABS.PRIMARY:
            return (
              <EmptyState
                title="No starred news yet"
                className={styles.emptyState}
                icon={<NoStarredNewsYet data-testid="starred-empty-state-icon" />}
              />
            )
          case TABS.HIDDEN:
            return (
              <EmptyState
                title="No hidden news yet"
                className={styles.emptyState}
                icon={<NoHiddenNewsYet data-testid="hidden-empty-state-icon" />}
              />
            )
        }
      } else {
        return (
          <WppGrid container fullHeight fullWidth all={24} data-testid="news-grid">
            {news.map(newsItem => (
              <WppGrid item all={6} key={newsItem.id}>
                <NewsCardWrapper openInsideOs={newsItem.open_inside_os} id={newsItem.id} postUrl={newsItem.post_url}>
                  <NewsCard
                    newsItem={newsItem}
                    locale={osContext.userDetails.dateLocale}
                    onEdit={() => setState({ isEditNewsItemModalOpen: true, newsItem })}
                    normalizedCountries={normalizedCountries}
                  />
                </NewsCardWrapper>
              </WppGrid>
            ))}
          </WppGrid>
        )
      }
    },
    [normalizedCountries, osContext.userDetails.dateLocale, selectedTab, setState],
  )

  const setTabDependFilterValue = (value: TABS) => {
    switch (value) {
      case TABS.ALL:
        return { hidden: false, primary: null }
      case TABS.PRIMARY:
        return { hidden: false, primary: true }
      case TABS.HIDDEN: {
        return { hidden: true, primary: null }
      }
    }
  }

  return (
    <>
      <EditNewsItem
        data={newsItem}
        isOpen={isEditNewsItemModalOpen}
        onClose={() => setState({ isEditNewsItemModalOpen: false })}
      />
      <Flex className={styles.header} gap={16} direction="column">
        <Flex justify="between">
          <WppTypography type="3xl-heading" data-testid="news-page-title">
            News
          </WppTypography>
          {!!credentialOptions.length && hasAccessToNewsSettings ? (
            <Link
              to="/settings"
              onClick={() => {
                osApi.analytics.track({
                  type: AnalyticsActionType.action,
                  payload: EVENTS.ACTIONS.SETTINGS,
                })
                osApi.analytics.track({
                  type: AnalyticsActionType.page,
                  payload: EVENTS.PAGE.SETTINGS,
                })
              }}
            >
              <WppButton variant="secondary">
                <WppIconService slot="icon-start" />
                News settings
              </WppButton>
            </Link>
          ) : null}
        </Flex>
        <Flex gap={20}>
          <WppInput
            size="s"
            type="search"
            onWppChange={({ detail }) => setSearchDebounced(detail.value)}
            placeholder="Search news by title"
            data-testid="news-card-search-input"
          />
          {hasAccessToNewsSettings && (
            <>
              <WppSegmentedControl
                value={selectedTab}
                size="s"
                onWppChange={({ detail: { value } }) => {
                  value && setState({ selectedTab: value as TABS, ...setTabDependFilterValue(value as TABS) })
                }}
              >
                <WppSegmentedControlItem value={TABS.ALL}>All</WppSegmentedControlItem>
                <WppSegmentedControlItem value={TABS.PRIMARY}>Starred</WppSegmentedControlItem>
                <WppSegmentedControlItem value={TABS.HIDDEN}>Hidden</WppSegmentedControlItem>
              </WppSegmentedControl>

              <div className={styles.selectContainer} data-testid="news-cards-filter-by-source">
                {isCredentialsLoading ? (
                  <WppSkeleton height={32} />
                ) : (
                  <Select
                    withSearch
                    withFolder
                    size="s"
                    type="multiple"
                    value={tenantCredentialsIds}
                    options={credentialOptions}
                    placeholder="Source"
                    onWppChange={event => {
                      setState({ tenantCredentialsIds: event.detail.value })
                    }}
                  />
                )}
              </div>

              <div className={styles.selectContainer} data-testid="news-cards-filter-by-country">
                {isCountriesLoading ? (
                  <WppSkeleton height={32} />
                ) : (
                  <Select
                    withSearch
                    withFolder
                    size="s"
                    type="multiple"
                    value={countryAlpha2Codes}
                    options={countriesOptions}
                    placeholder="Country"
                    onWppChange={event => {
                      setState({ countryAlpha2Codes: event.detail.value })
                    }}
                    renderOptionContent={({ label, logoThumbnail }) => (
                      <Flex slot="label" gap={8}>
                        <Avatar size="xs" src={logoThumbnail?.url || ''} name={label} style={{ minWidth: '24px' }} />
                        <span className={styles.countrySelectOptionLabel}>{label}</span>
                      </Flex>
                    )}
                  />
                )}
              </div>
            </>
          )}
        </Flex>
      </Flex>

      {content(isLoading, news)}
    </>
  )
}
